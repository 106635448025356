import { Link } from "@remix-run/react";
import { type HeadersFunction } from "@vercel/remix";
import { useEffect, useRef, useState } from "react";

import { getOptimizedImageUrl } from "~/utils/vercel";

export const headers: HeadersFunction = ({ parentHeaders }) => {
  return {
    Vary: parentHeaders.get("Vary") ?? "",
    "Cache-Control":
      "public, max-age=3600, s-max-age=86400, stale-while-revalidate=604800",
  };
};

export default function Index() {
  const audioRef = useRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    audioRef.current = new Audio(
      "https://topia-assets.s3.amazonaws.com/soundtrack.mp3",
    );
    audioRef.current.loop = true;
    audioRef.current.volume = 0.5;

    const audio = audioRef.current;

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    function parallax(event: MouseEvent) {
      const layers = document.querySelectorAll<HTMLElement>("[data-depth]");

      layers.forEach((layer) => {
        const depth = layer.getAttribute("data-depth");

        if (!depth) {
          return;
        }

        layer.style.setProperty("--x", String(event.clientX * Number(depth)));
        layer.style.setProperty("--y", String(event.clientY * Number(depth)));
      });
    }

    window.addEventListener("mousemove", parallax);

    return () => {
      window.removeEventListener("mousemove", parallax);
    };
  }, []);

  return (
    <div className="grid min-h-[100dvh] grid-rows-[100%,100%]">
      <div className="z-10 col-start-1 row-start-1 mx-auto w-full max-w-8xl px-10 pb-8 pt-16">
        <h1 className="mb-14">
          <img
            width="1568"
            height="314"
            decoding="async"
            src="/img/logo.svg"
            alt="HYTOPIA"
            className="max-h-[33vh] w-full"
          />
        </h1>
      </div>

      <div className="col-start-1 row-start-1 overflow-hidden">
        <img
          width="5000"
          height="2500"
          decoding="async"
          src={`${getOptimizedImageUrl("/img/bg.jpg", 5000)}`}
          alt="Background"
          className="h-full w-full translate-y-[-15%] transform object-cover xl:h-screen"
        />
      </div>

      <div className="z-10 col-start-1 row-start-1 overflow-hidden">
        <div className="h-full translate-y-[-15%] transform">
          <div
            className="h-full translate-x-[calc(var(--x)*1px)] translate-y-[calc(var(--y)*1px)] transition ease-linear will-change-transform"
            data-depth="0.005"
          >
            <img
              width="5000"
              height="2500"
              decoding="async"
              src={`${getOptimizedImageUrl("/img/mobs.png", 5000)}`}
              alt="Mobs"
              className="h-[75%] w-full object-cover sm:h-full xl:h-screen"
            />
          </div>
        </div>
      </div>

      <div className="z-20 col-start-1 row-start-1 overflow-hidden">
        <div className="h-full translate-y-[-15%] transform">
          <div
            className="h-full translate-x-[calc(var(--x)*1px)] translate-y-[calc(var(--y)*1px)] transition ease-linear will-change-transform"
            data-depth="-0.005"
          >
            <img
              width="5000"
              height="2500"
              decoding="async"
              src={`${getOptimizedImageUrl("/img/avatars.png", 5000)}`}
              alt="Main Character"
              className="h-[75%] w-full object-cover sm:h-full xl:h-screen"
            />
          </div>
        </div>
      </div>

      <div className="z-20 col-start-1 row-start-1 overflow-hidden">
        <div className="h-full translate-y-[-14%] transform">
          <div
            className="h-full translate-x-[calc(var(--x)*1px)] translate-y-[calc(var(--y)*1px)] transition ease-linear will-change-transform"
            data-depth="-0.02"
          >
            <img
              width="5000"
              height="2500"
              decoding="async"
              src={`${getOptimizedImageUrl("/img/flying.png", 5000)}`}
              alt="Flying Mobs"
              className="h-[75%] w-full object-cover sm:h-full xl:h-screen"
            />
          </div>
        </div>
      </div>

      <div className="z-30 col-start-1 row-start-1 w-full place-self-end [background:linear-gradient(180deg,rgba(0,0,0,0),rgba(0,0,0,.8)_35%)] sm:[background:linear-gradient(180deg,rgba(0,0,0,0),rgba(0,0,0,.8)_75%)]">
        <div className="mx-auto grid max-w-8xl grid-rows-[minmax(0,1fr),auto,auto] px-10 pb-8 pt-16 sm:grid-rows-[minmax(15vh,1fr),auto,auto]">
          <div className="z-10 mx-auto mb-10 mt-auto w-full text-center">
            <div className="mx-auto grid max-w-3xl grid-cols-[1fr,2fr] grid-rows-[repeat(2,auto)] gap-3 sm:gap-5 md:max-w-none md:grid-cols-[minmax(135px,1fr),minmax(0,56rem),minmax(135px,1fr)] md:grid-rows-none md:justify-between">
              <div className="col-start-1 row-start-2 md:place-self-start md:self-end">
                <button
                  type="button"
                  onClick={() => {
                    if (audioRef.current) {
                      if (isPlaying) {
                        audioRef.current.pause();
                      } else {
                        audioRef.current.play();
                      }
                      setIsPlaying(!isPlaying);
                    }
                  }}
                  className="group hidden w-full rounded-[14px] border-4 border-black/75 bg-black/30 text-xl font-bold backdrop-blur-[250px] transition sm:block"
                >
                  <span className="relative block rounded-[10px] border border-white/20 px-6 py-5 backdrop-blur-[250px] transition group-hover:border-white/10 group-hover:bg-white/5 group-hover:backdrop-blur-[50px] sm:py-6">
                    {isPlaying ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 24 24"
                        className="mx-auto h-[19px] w-[19px] 3xl:h-[21px] 3xl:w-[21px]"
                      >
                        <path
                          fill="currentColor"
                          d="M14 20.725v-2.05q2.25-.65 3.625-2.5t1.375-4.2q0-2.35-1.375-4.2T14 5.275v-2.05q3.1.7 5.05 3.138T21 11.975q0 3.175-1.95 5.613T14 20.725ZM3 15V9h4l5-5v16l-5-5H3Zm11 1V7.95q1.175.55 1.838 1.65T16.5 12q0 1.275-.663 2.363T14 16Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 24 24"
                        className="mx-auto h-[19px] w-[19px] 3xl:h-[21px] 3xl:w-[21px]"
                      >
                        <path
                          fill="currentColor"
                          d="m19.1 21.9l-2.325-2.325q-.35.2-.7.388t-.725.337q-.5.2-.925-.125t-.425-.9q0-.275.163-.487t.412-.313q.2-.075.388-.163t.362-.187L12 14.8v2.775q0 .675-.613.938T10.3 18.3L7 15H4q-.425 0-.713-.288T3 14v-4q0-.425.288-.713T4 9h2.2L2.1 4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l17 17q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275ZM15.35 3.65q2.55 1.025 4.1 3.275t1.55 5.05q0 1.325-.362 2.55T19.6 16.8l-1.45-1.45q.425-.775.638-1.625t.212-1.75q0-2.2-1.213-3.95t-3.212-2.55q-.275-.1-.425-.325t-.15-.5q0-.55.425-.875t.925-.125Zm.9 9.8L14 11.2V7.95q1.175.55 1.838 1.65T16.5 12q0 .375-.063.738t-.187.712ZM12 9.2L9.4 6.6l.9-.9q.475-.475 1.088-.213t.612.938V9.2Z"
                        />
                      </svg>
                    )}
                  </span>
                </button>
              </div>
              <div className="col-span-2 col-start-1 row-start-1 md:col-span-1 md:col-start-2 md:row-start-2">
                <h2 className="custom-shadow mb-7 text-5xl/tight font-black uppercase tracking-wider">
                  Imagination is limitless
                </h2>
                <p className="mb-10 text-2xl">
                  <span className="rainbow-diagonal bg-clip-text font-bold [-webkit-text-fill-color:transparent] [text-fill-color:transparent]">
                    Alpha version out now!
                  </span>
                </p>
                <div className="grid grid-cols-2 gap-3 sm:gap-5">
                  <Link
                    to="/games/"
                    className="group col-span-2 rounded-[14px] border-4 border-black/75 bg-black/30 text-2xl font-bold backdrop-blur-[250px] transition"
                  >
                    <span className="relative block rounded-[10px] border border-white/20 py-5 pl-8 pr-8 backdrop-blur-[250px] transition group-hover:border-white/10 group-hover:bg-white/5 group-hover:backdrop-blur-[50px] sm:py-6">
                      Play HYTOPIA (Alpha)
                    </span>
                  </Link>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://dev.hytopia.com"
                    className="group col-span-2 rounded-[14px] border-4 border-black/75 bg-black/30 text-2xl font-bold backdrop-blur-[250px] transition"
                  >
                    <span className="relative block rounded-[10px] border border-white/20 py-5 pl-8 pr-8 backdrop-blur-[250px] transition group-hover:border-white/10 group-hover:bg-white/5 group-hover:backdrop-blur-[50px] sm:py-6">
                      Start Building Games (View Docs)
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-start-1 col-end-3 row-start-2 flex items-center space-x-2.5 md:col-start-3 md:place-self-end md:self-end">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.gg/hytopia"
                  className="group inline-block flex-1 rounded-[14px] border-4 border-black/75 bg-black/30 text-xl font-bold backdrop-blur-[250px] transition"
                >
                  <span className="relative block rounded-[10px] border border-white/20 px-6 py-5 backdrop-blur-[250px] transition group-hover:border-white/10 group-hover:bg-white/5 group-hover:backdrop-blur-[50px] sm:py-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      width="21"
                      height="21"
                      viewBox="0 0 26 20"
                      className="mx-auto h-[19px] w-[19px] 3xl:h-[21px] 3xl:w-[21px]"
                    >
                      <path
                        fill="currentColor"
                        d="M22.024 1.675A21.271 21.271 0 0 0 16.66 0c-.253.46-.482.933-.687 1.418a19.685 19.685 0 0 0-5.953 0A15.108 15.108 0 0 0 9.333 0C7.48.32 5.675.885 3.965 1.68.571 6.776-.35 11.747.11 16.647A21.5 21.5 0 0 0 6.69 20a16.17 16.17 0 0 0 1.408-2.306A13.899 13.899 0 0 1 5.88 16.62c.186-.137.368-.278.544-.415A15.263 15.263 0 0 0 13 17.695c2.274 0 4.52-.51 6.577-1.491.178.147.36.288.544.415-.709.424-1.453.785-2.223 1.077.404.807.876 1.578 1.409 2.304a21.401 21.401 0 0 0 6.583-3.35c.54-5.683-.923-10.608-3.866-14.975ZM8.681 13.635c-1.282 0-2.342-1.182-2.342-2.635 0-1.452 1.023-2.644 2.338-2.644 1.315 0 2.366 1.192 2.343 2.644-.022 1.453-1.032 2.634-2.339 2.634Zm8.638 0c-1.284 0-2.34-1.182-2.34-2.635 0-1.452 1.023-2.644 2.34-2.644s2.36 1.192 2.337 2.644c-.022 1.453-1.03 2.634-2.337 2.634Z"
                      />
                    </svg>
                  </span>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/HYTOPIAgg"
                  className="group inline-block flex-1 rounded-[14px] border-4 border-black/75 bg-black/30 text-xl font-bold backdrop-blur-[250px] transition"
                >
                  <span className="relative block rounded-[10px] border border-white/20 px-6 py-5 backdrop-blur-[250px] transition group-hover:border-white/10 group-hover:bg-white/5 group-hover:backdrop-blur-[50px] sm:py-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      width="21"
                      height="21"
                      viewBox="0 0 28 22"
                      className="mx-auto h-[19px] w-[19px] 3xl:h-[21px] 3xl:w-[21px]"
                    >
                      <path
                        fill="currentColor"
                        d="M24.305 5.37a10 10 0 0 1 .016.704c0 7.196-5.588 15.496-15.806 15.496v-.004A15.957 15.957 0 0 1 0 19.124a11.297 11.297 0 0 0 8.221-2.257c-2.377-.044-4.461-1.564-5.19-3.782.833.157 1.691.125 2.509-.094-2.592-.513-4.457-2.746-4.457-5.338v-.07a5.604 5.604 0 0 0 2.522.682C1.164 6.666.41 3.483 1.885.994c2.82 3.402 6.982 5.47 11.45 5.69A5.382 5.382 0 0 1 14.94 1.48c2.238-2.062 5.757-1.956 7.86.236A11.283 11.283 0 0 0 26.328.394a5.487 5.487 0 0 1-2.441 3.012 11.221 11.221 0 0 0 3.19-.857 11.165 11.165 0 0 1-2.773 2.82Z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <footer className="flex items-center justify-between text-sm">
            <p className="opacity-60">© HYTOPIA {new Date().getFullYear()}</p>
          </footer>
        </div>
      </div>
    </div>
  );
}
